import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconArrowHead,
  ResponsiveImage,
  PrimaryButtonInline,
} from '../../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ListingImageGallery.module.css';

const MOBILE_SCREEN_WIDTH = 768;

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
};
const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : hasDimensions
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

const ListingImageGallery = props => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const { intl, rootClassName, className, images, imageVariants, thumbnailVariants } = props;
  const thumbVariants = thumbnailVariants || imageVariants;
  // imageVariants are scaled variants.
  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });
  const imageSizesMaybe = isFullscreen
    ? {}
    : { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };
  const renderItem = item => {
    return (
      <AspectRatioWrapper
        width={aspectWidth || 1}
        height={aspectHeight || 1}
        className={isFullscreen ? css.itemWrapperFullscreen : css.itemWrapper}
      >
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
            {...imageSizesMaybe}
          />
        </div>
      </AspectRatioWrapper>
    );
  };
  const renderThumbInner = item => {
    return (
      <div>
        <ResponsiveImage
          rootClassName={css.thumb}
          image={item.image}
          alt={item.thumbAlt}
          variants={thumbVariants}
          sizes="88px"
        />
      </div>
    );
  };

  const onScreenChange = isFull => {
    setIsFullscreen(isFull);
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };
  const renderFullscreenButton = (onClick, isFullscreen) => {
    return isFullscreen ? (
      <Button
        onClick={onClick}
        rootClassName={css.close}
        title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
      >
        <span className={css.closeText}>
          <FormattedMessage id="ListingImageGallery.closeModal" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    ) : (
      <button className={css.openFullscreen} onClick={onClick}>
        <FormattedMessage
          id="ListingImageGallery.viewImagesButton"
          values={{ count: images.length }}
        />
      </button>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const openFullscreen = (image) => {
    setIsFullscreen(true);
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
    setFullscreenImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_WIDTH);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const openModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
    setCurrentIndex(0);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const renderSlideshowControls = () => (
    <div className={css.slideshowControls}>
      <button onClick={prevImage} className={css.navButton}>
        <IconArrowHead direction="left" size="big" />
      </button>
      <button onClick={nextImage} className={css.navButton}>
        <IconArrowHead direction="right" size="big" />
      </button>
    </div>
  );

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const numberOfImages = images.length;

  const gridClass =
    numberOfImages === 3 ? css.gridThree : numberOfImages === 4 ? css.gridFour : css.grid;

  return (
    <div className={classNames(classes, css.galleryContainer)}>
      <div className={gridClass}>
        {images.slice(0, 5).map((img, i) => (
          <div key={i} className={css.gridItem}>
            <ResponsiveImage
              image={img}
              alt={intl.formatMessage(
                { id: 'ListingImageGallery.imageAltText' },
                { index: i + 1, count: images.length }
              )}
              variants={imageVariants}
            />
          </div>
        ))}
      </div>
      <PrimaryButtonInline onClick={openModal} className={css.viewAllButton}>
        <FormattedMessage id="ListingImageGallery.viewAllImages" />
      </PrimaryButtonInline>

      {modalOpen && (
        <div className={css.modalOverlay} onClick={closeModal}>
          <div className={css.modalContent} onClick={e => e.stopPropagation()}>
            <button className={css.closeModal} onClick={closeModal}>
              X
            </button>

            {isMobile ? (
              <div className={css.slideshow}>
                <ResponsiveImage
                  image={images[currentIndex]}
                  alt={intl.formatMessage({ id: 'ListingImageGallery.imageAltText' }, { index: currentIndex + 1, count: images.length })}
                  variants={imageVariants}
                />
                {renderSlideshowControls()}
              </div>
            ) : (
            <div className={css.modalGrid}>
              {images.map((img, i) => (
                <div key={i} className={css.modalGridItem}>
                  <ResponsiveImage
                    image={img}
                    alt={intl.formatMessage(
                      { id: 'ListingImageGallery.imageAltText' },
                      { index: i + 1, count: images.length }
                    )}
                    variants={imageVariants}
                    onClick={() => openFullscreen(img)}
                  />
                </div>
              ))}
            </div>
            )}
          </div>
        </div>
      )}

      {isFullscreen && (
        <div className={css.fullscreenOverlay} onClick={closeFullscreen}>
          <div className={css.fullscreenContent}>
            <ResponsiveImage
              image={fullscreenImage}
              alt="Fullscreen Image"
              variants={imageVariants}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  images: PropTypes.arrayOf(propTypes.image).isRequired,
  imageVariants: PropTypes.arrayOf(PropTypes.string).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
