// IconTooltip.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconTooltip.module.css';

const IconTooltip = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.4051" cy="12.9999" r="11.5946" fill="#AEAEB2"/>
      <path d="M13.3489 8.90123C12.9684 8.90123 12.6654 8.80962 12.4399 8.6264C12.2144 8.42909 12.1016 8.16836 12.1016 7.8442C12.1016 7.4073 12.2708 7.03382 12.609 6.72376C12.9473 6.4137 13.3489 6.25867 13.814 6.25867C14.2227 6.25867 14.5187 6.38551 14.7019 6.6392C14.8851 6.87879 14.9768 7.12543 14.9768 7.37911C14.9768 7.83011 14.8147 8.19655 14.4905 8.47842C14.1805 8.76029 13.7999 8.90123 13.3489 8.90123ZM12.8416 18.0762C12.7993 18.2453 12.7781 18.3722 12.7781 18.4567C12.7781 18.5836 12.8416 18.647 12.9684 18.647C13.053 18.647 13.1516 18.6259 13.2644 18.5836C13.3912 18.5272 13.511 18.4708 13.6238 18.4145C13.7365 18.344 13.8422 18.2876 13.9409 18.2453C14.0395 18.189 14.117 18.1608 14.1734 18.1608C14.2862 18.1608 14.3707 18.2242 14.4271 18.351C14.4976 18.4638 14.5399 18.5765 14.5539 18.6893C14.3284 18.8443 14.0607 19.0134 13.7506 19.1967C13.4546 19.3799 13.1446 19.549 12.8204 19.704C12.5104 19.8591 12.2003 19.9859 11.8902 20.0846C11.5943 20.1973 11.3335 20.2537 11.108 20.2537C10.8684 20.2537 10.6641 20.2044 10.495 20.1057C10.3399 20.007 10.2624 19.8379 10.2624 19.5983C10.2624 19.5138 10.2695 19.4363 10.2836 19.3658C10.2977 19.2812 10.3117 19.2108 10.3258 19.1544L11.6788 12.7065C11.7211 12.4951 11.6859 12.3894 11.5731 12.3894C11.5309 12.3894 11.4674 12.4106 11.3829 12.4528C11.2983 12.481 11.1996 12.5163 11.0869 12.5585C10.9882 12.6008 10.8825 12.6431 10.7698 12.6854C10.6711 12.7136 10.5866 12.7277 10.5161 12.7277C10.3893 12.7277 10.2906 12.6642 10.2201 12.5374C10.1638 12.4106 10.1215 12.2908 10.0933 12.178C10.3611 12.0371 10.6782 11.875 11.0446 11.6918C11.4251 11.5086 11.7916 11.3465 12.1439 11.2055C12.5104 11.0505 12.8416 10.9237 13.1375 10.825C13.4335 10.7123 13.6449 10.6559 13.7717 10.6559C13.8845 10.6559 13.9902 10.6841 14.0889 10.7405C14.1875 10.7968 14.2368 10.8955 14.2368 11.0364C14.2368 11.0787 14.2298 11.1351 14.2157 11.2055C14.2157 11.276 14.2086 11.3465 14.1946 11.417L12.8416 18.0762Z" fill="white"/>
    </svg>
  );
};

IconTooltip.defaultProps = { className: null };

IconTooltip.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTooltip; // Ensure this is a default export
